:root {
  --main-color: #6ba530;
  --sec-color: #666E5E;
  --base-color: #FAFDF7;

  --default-width: 1300px;
}

html{
  /*1rem -> 10px*/ 
  font-size: 62.5%;
}


@media screen and (max-width: 1024px){
  html{
    font-size: 50%;
  }
}

@media screen and (max-width: 768px){
  html{
    font-size: 35%;
  }
}

@media screen and (max-width: 425px){
  html{
    font-size: 25%;
  }
}


body{
  font-size: 1.6rem;
}


body {
  margin: 0;
  font-family: -apnpple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "prelo-bold";
  src: url("./assets/fonts/Prelo-Bold.ttf");
}

@font-face {
  font-family: "prelo-semibold";
  src: url("./assets/fonts/Prelo-SemiBold.ttf");
}

@font-face {
  font-family: "prelo-book";
  src: url("./assets/fonts/Prelo-Book.ttf");
}

@font-face {
  font-family: "prelo-extrabold";
  src: url("./assets/fonts/Prelo-ExtraBold.ttf");
}

/* width */
/* ::-webkit-scrollbar {
  width: 10px;
} */

/* body::-webkit-scrollbar-track { */
  /* background:  none color of the tracking area */
/* }  */

/* Track */
/* ::-webkit-scrollbar-track { */
  /* box-shadow: inset 0 0 5px grey; */
  /* border-radius: 0px; */
/* } */

/* Handle */
/* ::-webkit-scrollbar-thumb { */
  /* background: var(--main-color); */
  /* border-radius: 10px; */
  /* border: 3px solid var(--base-color) */
/* } */


/* .slick-slide {
  padding: 0 20px;
} */

/* .slick-list {
  margin: 0 2em;
} */



.slick-dots li.slick-active button:before {
  color:var(--main-color) !important;
}


.slick-dots li button:hover:before {
  color:var(--main-color) !important;
}

.slick-slide > div {
  display: grid;
  place-items: center;
}


