*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'prelo-book';
  text-decoration: none;
}

#landingPage{
  display: block;
  background: #FAFDF7;
  max-width: 100%;
}